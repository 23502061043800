import React, { useEffect, useState } from 'react'
import './App.css';
import Home from './components/Home';
import {Route, Switch} from 'react-router-dom';
import PDFViewer from './PDF/PDFViewer';
import { GridView } from './components/GridView';



export const App = () => {

const currentZineLocalStorage = JSON.parse(localStorage.getItem('currentZine'))

const [currentZine, getCurrentZine] = useState({currentZineLocalStorage});
const [fullIntro, seeFullIntro] = useState(false);


useEffect(() => {
  localStorage.setItem('currentZine', JSON.stringify(currentZine));
}, [currentZine])

   
    return (
      <div className = "top-grid">

      <Switch>
        <Route path = "/grid">
          <GridView getCurrentZine={ getCurrentZine }></GridView>
        </Route>

        <Route path = "/viewer">
          <PDFViewer currentZine={ currentZine } currentZineLocalStorage = {currentZineLocalStorage}></PDFViewer>
        </Route>

        <Route path = '/'>
          <Home fullIntro={ fullIntro } seeFullIntro={ seeFullIntro } getCurrentZine={ getCurrentZine }></Home>
        </Route>
        
      </Switch>
      </div>
  )
}

export default App;
