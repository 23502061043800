import React, { Component } from "react";
import "./home.css";
import video from "./MorusBackground.mp4";
import ZineSlidesShow from "./ZineSlideShow"
import Fade from "react-reveal/Fade"
import { Link } from "react-router-dom"
import { Link as Scroll } from "react-scroll"



class Home extends Component {

  //Line 15 - 34, functions that open a new browser tab for external sites. 

  goToQZAP = () => {
    window.open("https://www.zinelibraries.info/wp-content/uploads/2021/05/Zinecore_Zine_Flats.pdf")
  }

  goToThesaurus = () => {
    window.open("https://anchorarchive.org/subject-thesaurus")
  }


  goToZineCat = () => {
    window.open("https://zinecat.org/")
  }

  goToMorus = () => {
    window.open("http://www.morusnyc.org/")
  }

  goToDonate = () => {
    window.open("http://www.morusnyc.org/support-us/donate/")
  } 


 render() {

  return(
  
      <div className="high-div" >
        <header className="wrapper" >
          <video className="video-background" autoPlay muted loop>
            <source src={ video } type="video/mp4" />
          </video>
          <h1>MORUS<br></br><span className="zine-library" >Zine Library</span></h1 >
        </header>
        <main className="opening-top-section" > 
              <div className="div-nav" >
                    <nav className="nav-tag" >
                      <Link to="/grid" > 
                        <button className = "button-54 gallery-view" >Gallery View</button >
                      </Link>
                      { this.props.fullIntro === false ?                                                                                   
                        <Scroll to="about" smooth={true} duration={2000} >                                                                
                          <button className="button-54 intro-btn" onClick={ () => this.props.seeFullIntro() } >Introduction</button >     
                        </Scroll> :
                          <button className="button-54 intro-btn" onClick={ () => this.props.seeFullIntro(false) }>Hide Intro</button >
                      }
                        <Scroll to="single-view" smooth={true} duration={2000} >
                          <button className="button-54" >Single View </button >
                        </Scroll>
                    </nav> 
                    <div className="externals" >
                        <button onClick={ () => this.goToMorus() } className="morus-website" >MORUS Website</button >
                        <button onClick={ () => this.goToDonate() } className="donate" >Support Our Mission</button >
                    </div>  
              </div>  
              { this.props.fullIntro === false ? null : 
                    <div id="about" >
                      <Fade clear>
                        <p className="opening" >
                          The Museum of Reclaimed Urban Space Zine Library was created to support the museum’s mission of preserving and promoting the history of 
                          grassroots activism in the Lower East Side of Manhattan. This library elevates and makes accessible the oftentimes disenfranchised and 
                          marginalized voices represented through these typically free publications. First organized in 2021 by the Archive Committee at MoRUS, 
                          the MoRUS Zine Library was made up of donations from visitors and former museum volunteers. Moving forward the scope of our zine collection 
                          policy will be focused on subjects and genres ranging from DIY, history, and art that deal with topics like housing, environmentalism, and 
                          activism, especially those emanating from New York City. Despite these collection restrictions, the library will continue to reflect the 
                          diverse communities of stakeholders concerned with each of these topics. While our physical collection currently tops out at about 100 different
                          zines (and will continue to grow) this digital repository provides a selection of those zines. The MoRUS Zine Library is made up largely of donations. If you see your work featured in the library and wish to be more clearly credited, removed from the 
                          library, or the documentation of the zine changed in any way please don't hesitate to reach out to the archive coordinator. Other zines can be made available by reaching out 
                          to the archive coordinator at archives@morusnyc.org or info@morusnyc.org. 
                        </p>
                        <p className="opening-p2" >
                          The Zine Library is organized according to subject in our database which uses the xZineCorex metadata schema, explained by Milo of QZAP in an 
                          easy to read and very helpful zine <span onClick = {() => this.goToQZAP()} className = "zinecat">here</span>, and the subject terms were derived 
                          largely from the <span onClick = {() => this.goToThesaurus()} className = "zinecat">Anchor Archive Zine Subject Thesaurus</span>. The goal of our 
                          adherence to these zine specific standards is to contribute to the ongoing project of Zine Union Catalog to normalize metadata in libraries around 
                          the world. Long term we hope to participate in the ongoing project of creating a worldwide open source catalog of zines which can connect any library 
                          that holds zines. More details on that project at here at <span className = "zinecat" onClick = {() => this.goToZineCat()}> zinecat.org</span>. 
                        </p > 
                        </Fade>
                    </div>
              }
        </main>
        <Fade clear >
          <section className="zine-selection" id="single-view" >
            <ZineSlidesShow getCurrentZine={this.props.getCurrentZine} ></ZineSlidesShow>
          </section>
        </Fade>
      </div>
    )
   }
 }

export default Home