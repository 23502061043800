import React, { useEffect, useState } from 'react'
import allZines from '../PDF/PDF';
import { GridViewCard } from './GridViewCard';
import './grid.css'
import { Icon } from 'react-icons-kit'
import { home } from 'react-icons-kit/icomoon/home'
import { Link } from 'react-router-dom'
import {circleTop} from 'react-icons-kit/iconic/circleTop'
import {Link as Scroll} from 'react-scroll'



export const GridView  = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []) 

    const [searchBySubject, setSearch] = useState("All")

    let filteredZines = allZines
    if(searchBySubject === "All"){
        filteredZines = allZines
    } else {
        filteredZines = allZines.filter(zine => zine.subject.toLowerCase().includes(searchBySubject.toLowerCase()))
    }

  return(
    <div className= "top-grid-div" id = "top-grid-div"> 
        <div className = 'grid-header'>  
            <label>
                <strong>Filter by Subject</strong>
                <select placeholder = "filter" className = "filter" onChange={(e) => setSearch(e.target.value)}>
                <option value="All">All</option>
                <option value="Activism">Activism</option>
                <option value="Anarchism">Anarchism</option>
                <option value="Art">Art</option>
                <option value="Bicycles">Bicycles</option>
                <option value="Climate Change">Climate Change</option>
                <option value="Community">Community</option>
                <option value="DIY">DIY</option>
                <option value="Environmentalism">Environmentalism</option>
                <option value="Feminism">Feminism</option>
                <option value="Gardening">Gardening</option>
                <option value="History">History</option>
                <option value="Housing">Housing</option>
                <option value="Labor">Labor</option>
                <option value="Police">Police</option>
                <option value="Politics">Politics</option>
                <option value="Prisons">Prisons</option>
                <option value="Race">Race</option>
                <option value="Squatting">Squatting</option>
                <option value="Surveillance">Surveillance</option>
                </select>
            </label>
            <Link to ='/'>
                <button className = "home">
                    <Icon icon = {home} size = {'46%'} />
                    <p className = "m-button">MORUS</p>
                </button>
            </Link>    
        </div>    
        <div className = "grid-div">
            {filteredZines.map(zine => <GridViewCard getCurrentZine = {props.getCurrentZine} key = {zine.codeID} zine = {zine}/>)}
        </div>
        <Scroll to = "top-grid-div" smooth= {true} duration = {4000}>
            <button className = "back-to-top">
                <Icon icon = {circleTop} size = {'40%'} />
                <p>Top</p>
            </button>
        </Scroll>
    </div> 
   )
 }