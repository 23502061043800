import React, { Component } from 'react'
import { Fade as SlideShowFade } from "react-slideshow-image";
import { Link } from 'react-router-dom'
import "react-slideshow-image/dist/styles.css";
import "./slideshow.css"
import allZines from '../PDF/PDF';


class ZineSlidesShow extends Component {

 state = {}

 render() {
  return(
    <div className = "slide-div">
        
       <SlideShowFade width = "1000px" duration = {450000}>
            <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[0])}>
                <Link to = '/viewer' >
                    <img src={allZines[0].cover_page_url} alt = {allZines[0].title}  className = "vh-image"/>
                </Link >   
                <div className = "metadata">
                    <h2 className = "title">{allZines[0].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[0].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[0].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[0].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[0].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[0].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[0].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[0].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[0].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[0].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[0].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[0].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[0].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[0].freedoms_and_restrictions}</p>
                </div>
            </div>
            <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[1])}>
                <Link to = '/viewer'>
                    <img src={allZines[1].cover_page_url} alt = {allZines[1].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[1].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[1].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[1].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[1].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[1].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[1].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[1].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[1].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[1].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[1].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[1].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[1].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[1].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[1].freedoms_and_restrictions}</p>
                </div>
            </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[2])}>
                <Link to = '/viewer'>
                    <img src={allZines[2].cover_page_url} alt = {allZines[2].title}  className = "vh-image"/>
                </Link>  
            <div className="metadata">
                    <h2 className = "title">{allZines[2].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[2].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[2].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[2].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[2].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[2].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[2].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[2].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[2].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[2].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[2].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[2].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[2].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[2].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[3])}>
                <Link to = '/viewer'>
                    <img src={allZines[3].cover_page_url} alt = {allZines[3].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[3].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[3].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[3].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[3].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[3].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[3].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[3].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[3].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[3].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[3].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[3].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[3].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[3].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[3].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[4])}>
                <Link to = '/viewer'>
                    <img src={allZines[4].cover_page_url} alt = {allZines[4].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[4].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[4].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[4].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[4].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[4].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[4].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[4].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[4].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[4].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[4].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[4].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[4].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[4].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[4].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[5])}>
                <Link to = '/viewer'>
                    <img src={allZines[5].cover_page_url} alt = {allZines[5].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[5].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[5].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[5].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[5].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[5].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[5].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[5].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[5].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[5].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[5].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[5].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[5].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[5].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[5].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[6])}>
                <Link to = '/viewer'>
                    <img src={allZines[6].cover_page_url} alt = {allZines[6].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[6].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[6].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[6].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[6].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[6].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[6].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[6].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[6].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[6].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[6].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[6].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[6].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[6].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[6].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[7])}>
                <Link to = '/viewer'>
                    <img src={allZines[7].cover_page_url} alt = {allZines[7].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[7].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[7].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[7].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[7].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[7].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[7].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[7].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[7].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[7].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[7].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[7].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[7].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[7].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[7].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[8])}>
                <Link to = '/viewer'>
                    <img src={allZines[8].cover_page_url} alt = {allZines[8].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[8].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[8].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[8].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[8].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[8].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[8].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[8].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[8].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[8].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[8].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[8].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[8].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[8].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[8].freedoms_and_restrictions}</p>
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[9])}>
                <Link to = '/viewer'>
                    <img src={allZines[9].cover_page_url} alt = {allZines[9].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[9].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[9].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[9].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[9].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[9].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[9].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[9].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[9].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[9].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[9].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[9].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[9].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[9].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[9].freedoms_and_restrictions}</p>  
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[10])}>
                <Link to = '/viewer'>
                    <img src={allZines[10].cover_page_url} alt = {allZines[10].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[10].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[10].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[10].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[10].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[10].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[10].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[10].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[10].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[10].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[10].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[10].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[10].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[10].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[10].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[11])}>
                <Link to = '/viewer'>
                    <img src={allZines[11].cover_page_url} alt = {allZines[11].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[11].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[11].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[11].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[11].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[11].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[11].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[11].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[11].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[11].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[11].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[11].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[11].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[11].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[11].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[12])}>
                <Link to = '/viewer'>
                    <img src={allZines[12].cover_page_url} alt = {allZines[12].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[12].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[12].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[12].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[12].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[12].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[12].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[12].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[12].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[12].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[12].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[12].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[12].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[12].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[12].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[13])}>
                <Link to = '/viewer'>
                    <img src={allZines[13].cover_page_url} alt = {allZines[13].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[13].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[13].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[13].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[13].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[13].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[13].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[13].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[13].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[13].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[13].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[13].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[13].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[13].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[13].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[14])}>
                <Link to = '/viewer'>
                    <img src={allZines[14].cover_page_url} alt = {allZines[14].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[14].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[14].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[14].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[14].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[14].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[14].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[14].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[14].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[14].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[14].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[14].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[14].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[14].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[14].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[15])}>
                <Link to = '/viewer'>
                    <img src={allZines[15].cover_page_url} alt = {allZines[15].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[15].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[15].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[15].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[15].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[15].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[15].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[15].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[15].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[15].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[15].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[15].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[15].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[15].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[15].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[16])}>
                <Link to = '/viewer'>
                    <img src={allZines[16].cover_page_url} alt = {allZines[16].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[16].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[16].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[16].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[16].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[16].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[16].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[16].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[16].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[16].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[16].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[16].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[16].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[16].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[16].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[17])}>
                <Link to = '/viewer'>
                    <img src={allZines[17].cover_page_url} alt = {allZines[17].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[17].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[17].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[17].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[17].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[17].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[17].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[17].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[17].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[17].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[17].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[17].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[17].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[17].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[17].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[18])}>
                <Link to = '/viewer'>
                    <img src={allZines[18].cover_page_url} alt = {allZines[18].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[18].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[18].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[18].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[18].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[18].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[18].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[18].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[18].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[18].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[18].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[18].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[18].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[18].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[18].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[19])}>
                <Link to = '/viewer'>
                    <img src={allZines[19].cover_page_url} alt = {allZines[19].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[19].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[19].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[19].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[19].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[19].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[19].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[19].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[19].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[19].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[19].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[19].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[19].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[19].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[19].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[20])}>
                <Link to = '/viewer'>
                    <img src={allZines[20].cover_page_url} alt = {allZines[20].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[20].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[20].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[20].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[20].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[20].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[20].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[20].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[20].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[20].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[20].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[20].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[20].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[20].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[20].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[21])}>
                <Link to = '/viewer'>
                    <img src={allZines[21].cover_page_url} alt = {allZines[21].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[21].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[21].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[21].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[21].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[21].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[21].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[21].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[21].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[21].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[21].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[21].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[21].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[21].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[21].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[22])}>
                <Link to = '/viewer'>
                    <img src={allZines[22].cover_page_url} alt = {allZines[22].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[22].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[22].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[22].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[22].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[22].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[22].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[22].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[22].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[22].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[22].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[22].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[22].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[22].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[22].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[23])}>
                <Link to = '/viewer'>
                    <img src={allZines[23].cover_page_url} alt = {allZines[23].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[23].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[23].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[23].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[23].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[23].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[23].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[23].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[23].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[23].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[23].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[23].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[23].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[23].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[23].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[24])}>
                <Link to = '/viewer'>
                    <img src={allZines[24].cover_page_url} alt = {allZines[24].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[24].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[24].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[24].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[24].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[24].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[24].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[24].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[24].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[24].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[24].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[24].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[24].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[24].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[24].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[25])}>
                <Link to = '/viewer'>
                    <img src={allZines[25].cover_page_url} alt = {allZines[25].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[25].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[25].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[25].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[25].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[25].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[25].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[25].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[25].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[25].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[25].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[25].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[25].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[25].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[25].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[26])}>
                <Link to = '/viewer'>
                    <img src={allZines[26].cover_page_url} alt = {allZines[26].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[26].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[26].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[26].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[26].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[26].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[26].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[26].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[26].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[26].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[26].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[26].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[26].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[26].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[26].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[27])}>
                <Link to = '/viewer'>
                    <img src={allZines[27].cover_page_url} alt = {allZines[27].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[27].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[27].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[27].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[27].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[27].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[27].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[27].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[27].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[27].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[27].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[27].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[27].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[27].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[27].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[28])}>
                <Link to = '/viewer'>
                    <img src={allZines[28].cover_page_url} alt = {allZines[28].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[28].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[28].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[28].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[28].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[28].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[28].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[28].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[28].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[28].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[28].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[28].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[28].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[28].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[28].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[29])}>
                <Link to = '/viewer'>
                    <img src={allZines[29].cover_page_url} alt = {allZines[29].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[29].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[29].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[29].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[29].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[29].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[29].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[29].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[29].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[29].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[29].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[29].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[29].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[29].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[29].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[30])}>
                <Link to = '/viewer'>
                    <img src={allZines[30].cover_page_url} alt = {allZines[30].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[30].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[30].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[30].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[30].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[30].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[30].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[30].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[30].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[30].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[30].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[30].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[30].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[30].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[30].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[31])}>
                <Link to = '/viewer'>
                    <img src={allZines[31].cover_page_url} alt = {allZines[31].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[31].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[31].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[31].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[31].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[31].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[31].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[31].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[31].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[31].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[31].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[31].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[31].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[31].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[31].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[32])}>
                <Link to = '/viewer'>
                    <img src={allZines[32].cover_page_url} alt = {allZines[32].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[32].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[32].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[32].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[32].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[32].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[32].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[32].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[32].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[32].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[32].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[32].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[32].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[32].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[32].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[33])}>
                <Link to = '/viewer'>
                    <img src={allZines[33].cover_page_url} alt = {allZines[33].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[33].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[33].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[33].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[33].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[33].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[33].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[33].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[33].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[33].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[33].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[33].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[33].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[33].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[33].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[34])}>
                <Link to = '/viewer'>
                    <img src={allZines[34].cover_page_url} alt = {allZines[34].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[34].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[34].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[34].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[34].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[34].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[34].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[34].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[34].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[34].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[34].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[34].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[34].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[34].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[34].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[35])}>
                <Link to = '/viewer'>
                    <img src={allZines[35].cover_page_url} alt = {allZines[35].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[35].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[35].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[35].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[35].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[35].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[35].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[35].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[35].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[35].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[35].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[35].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[35].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[35].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[35].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[36])}>
                <Link to = '/viewer'>
                    <img src={allZines[36].cover_page_url} alt = {allZines[36].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[36].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[36].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[36].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[36].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[36].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[36].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[36].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[36].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[36].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[36].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[36].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[36].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[36].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[36].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[37])}>
                <Link to = '/viewer'>
                    <img src={allZines[37].cover_page_url} alt = {allZines[37].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[37].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[37].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[37].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[37].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[37].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[37].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[37].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[37].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[37].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[37].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[37].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[37].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[37].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[37].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[38])}>
                <Link to = '/viewer'>
                    <img src={allZines[38].cover_page_url} alt = {allZines[38].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[38].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[38].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[38].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[38].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[38].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[38].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[38].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[38].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[38].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[38].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[38].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[38].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[38].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[38].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[39])}>
                <Link to = '/viewer'>
                    <img src={allZines[39].cover_page_url} alt = {allZines[39].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[39].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[39].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[39].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[39].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[39].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[39].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[39].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[39].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[39].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[39].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[39].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[39].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[39].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[39].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[64])}>
                <Link to = '/viewer'>
                    <img src={allZines[64].cover_page_url} alt = {allZines[64].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[38].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[64].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[64].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[64].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[64].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[64].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[64].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[64].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[64].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[64].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[64].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[64].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[64].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[64].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[40])}>
                <Link to = '/viewer'>
                    <img src={allZines[40].cover_page_url} alt = {allZines[40].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[40].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[40].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[40].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[40].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[40].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[40].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[40].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[40].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[40].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[40].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[40].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[40].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[40].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[40].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[41])}>
                <Link to = '/viewer'>
                    <img src={allZines[41].cover_page_url} alt = {allZines[41].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[41].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[41].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[41].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[41].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[41].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[41].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[41].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[41].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[41].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[41].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[41].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[41].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[41].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[41].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[42])}>
                <Link to = '/viewer'>
                    <img src={allZines[42].cover_page_url} alt = {allZines[42].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[42].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[42].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[42].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[42].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[42].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[42].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[42].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[42].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[42].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[42].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[42].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[42].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[42].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[42].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[43])}>
                <Link to = '/viewer'>
                    <img src={allZines[43].cover_page_url} alt = {allZines[43].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[43].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[43].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[43].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[43].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[43].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[43].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[43].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[43].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[43].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[43].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[43].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[43].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[43].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[43].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[44])}>
                <Link to = '/viewer'>
                    <img src={allZines[44].cover_page_url} alt = {allZines[44].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[44].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[44].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[44].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[44].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[44].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[44].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[44].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[44].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[44].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[44].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[44].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[44].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[44].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[44].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[45])}>
                <Link to = '/viewer'>
                    <img src={allZines[45].cover_page_url} alt = {allZines[45].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[45].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[45].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[45].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[45].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[45].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[45].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[45].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[45].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[45].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[45].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[45].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[45].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[45].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[45].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[46])}>
                <Link to = '/viewer'>
                    <img src={allZines[46].cover_page_url} alt = {allZines[46].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[46].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[46].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[46].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[46].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[46].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[46].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[46].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[46].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[46].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[46].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[46].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[46].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[46].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[46].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[47])}>
                <Link to = '/viewer'>
                    <img src={allZines[47].cover_page_url} alt = {allZines[47].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[47].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[47].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[47].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[47].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[47].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[47].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[47].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[47].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[47].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[47].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[47].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[47].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[47].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[47].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[48])}>
                <Link to = '/viewer'>
                    <img src={allZines[48].cover_page_url} alt = {allZines[48].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[48].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[48].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[48].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[48].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[48].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[48].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[48].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[48].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[48].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[48].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[48].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[48].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[48].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[48].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[49])}>
                <Link to = '/viewer'>
                    <img src={allZines[49].cover_page_url} alt = {allZines[49].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[49].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[49].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[49].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[49].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[49].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[49].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[49].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[49].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[49].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[49].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[49].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[49].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[49].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[49].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[50])}>
                <Link to = '/viewer'>
                    <img src={allZines[50].cover_page_url} alt = {allZines[50].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[50].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[50].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[50].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[50].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[50].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[50].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[50].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[50].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[50].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[50].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[50].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[50].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[50].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[50].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[51])}>
                <Link to = '/viewer'>
                    <img src={allZines[51].cover_page_url} alt = {allZines[51].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[51].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[51].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[51].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[51].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[51].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[51].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[51].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[51].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[51].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[51].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[51].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[51].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[51].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[51].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[52])}>
                <Link to = '/viewer'>
                    <img src={allZines[52].cover_page_url} alt = {allZines[53].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[52].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[52].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[52].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[52].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[52].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[52].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[52].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[52].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[52].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[52].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[52].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[52].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[52].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span> {allZines[52].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[53])}>
                <Link to = '/viewer'>
                    <img src={allZines[53].cover_page_url} alt = {allZines[53].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[53].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[53].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[53].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[53].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[53].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[53].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[53].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[53].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[53].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[53].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[53].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[53].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[53].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[53].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[54])}>
                <Link to = '/viewer'>
                    <img src={allZines[54].cover_page_url} alt = {allZines[54].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[54].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[54].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[54].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[54].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[54].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[54].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[54].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[54].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[54].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[54].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[54].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[54].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[54].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[54].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[55])}>
                <Link to = '/viewer'>
                    <img src={allZines[55].cover_page_url} alt = {allZines[55].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[55].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[55].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[55].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[55].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[55].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[55].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[55].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[55].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[55].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[55].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[55].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[55].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[55].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[55].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[56])}>
                <Link to = '/viewer'>
                    <img src={allZines[56].cover_page_url} alt = {allZines[56].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[56].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[56].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[56].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[56].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[56].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[56].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[56].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[56].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[56].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[56].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[56].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[56].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[56].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[56].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[57])}>
                <Link to = '/viewer'>
                    <img src={allZines[57].cover_page_url} alt = {allZines[57].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[57].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[57].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[57].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[57].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[57].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[57].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[57].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[57].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[57].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[57].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[57].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[57].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[57].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[57].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[58])}>
                <Link to = '/viewer'>
                    <img src={allZines[58].cover_page_url} alt = {allZines[58].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[58].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[58].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[58].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[58].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[58].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[58].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[58].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[58].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[58].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[58].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[58].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[58].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[58].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[58].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[59])}>
                <Link to = '/viewer'>
                    <img src={allZines[59].cover_page_url} alt = {allZines[59].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[59].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[59].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[59].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[59].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[59].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[59].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[59].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[59].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[59].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[59].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[59].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[59].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[59].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[59].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[60])}>
                <Link to = '/viewer'>
                    <img src={allZines[60].cover_page_url} alt = {allZines[60].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[60].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[60].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[60].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[60].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[60].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[60].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[60].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[60].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[60].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[60].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[60].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[60].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[60].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[60].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[61])}>
                <Link to = '/viewer'>
                    <img src={allZines[61].cover_page_url} alt = {allZines[61].title}   className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[61].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[61].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[61].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[61].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[61].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[61].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[61].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[61].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[61].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[61].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[61].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[61].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[61].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[61].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[62])}>
                <Link to = '/viewer'>
                    <img src={allZines[62].cover_page_url} alt = {allZines[62].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[62].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[62].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[62].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[62].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[62].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[62].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[62].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[62].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[62].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[62].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[62].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[62].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[62].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[62].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[63])}>
                <Link to = '/viewer'>
                    <img src={allZines[63].cover_page_url} alt = {allZines[63].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[63].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[63].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[63].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[63].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[63].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[63].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[63].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[63].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[63].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[63].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[63].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[63].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[63].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[63].freedoms_and_restrictions}</p> 
                </div>
        </div> 
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[64])}>
                <Link to = '/viewer'>
                    <img src={allZines[64].cover_page_url} alt = {allZines[64].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[64].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[64].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[64].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[64].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[64].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[64].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[64].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[64].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[64].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[64].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[64].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[64].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[64].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[64].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[65])}>
                <Link to = '/viewer'>
                    <img src={allZines[65].cover_page_url} alt = {allZines[65].title}   className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[65].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[65].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[65].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[65].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[65].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[65].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[65].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[65].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[65].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[65].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[65].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[65].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[65].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[65].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[66])}>
                <Link to = '/viewer'>
                    <img src={allZines[66].cover_page_url} alt = {allZines[66].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[66].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[66].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[66].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[66].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[66].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[66].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[66].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[66].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[66].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[66].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[66].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[66].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[66].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[66].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[67])}>
                <Link to = '/viewer'>
                    <img src={allZines[67].cover_page_url} alt = {allZines[67].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[67].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[67].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[67].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[67].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[67].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[67].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[67].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[67].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[67].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[67].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[67].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[67].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[67].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[67].freedoms_and_restrictions}</p> 
                </div>
        </div> 
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[68])}>
                <Link to = '/viewer'>
                    <img src={allZines[68].cover_page_url} alt = {allZines[68].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[68].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[68].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[68].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[68].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[68].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[68].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[68].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[68].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[68].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[68].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[68].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[68].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[68].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[68].freedoms_and_restrictions}</p> 
                </div>
        </div>   
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[69])}>
                <Link to = '/viewer'>
                    <img src={allZines[69].cover_page_url} alt = {allZines[69].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[69].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[69].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[69].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[69].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[69].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[69].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[69].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[69].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[69].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[69].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[69].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[69].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[69].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[69].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[70])}>
                <Link to = '/viewer'>
                    <img src={allZines[70].cover_page_url} alt = {allZines[70].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[70].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[70].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[70].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[70].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[70].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[70].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[70].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[70].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[70].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[70].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[70].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[70].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[70].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[70].freedoms_and_restrictions}</p> 
                </div>
        </div> 
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[71])}>
                <Link to = '/viewer'>
                    <img src={allZines[71].cover_page_url} alt = {allZines[71].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[71].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[71].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[71].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[71].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[71].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[71].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[71].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[71].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[71].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[71].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[71].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[71].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[71].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[71].freedoms_and_restrictions}</p> 
                </div>
        </div> 
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[72])}>
                <Link to = '/viewer'>
                    <img src={allZines[72].cover_page_url} alt = {allZines[72].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[72].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[72].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[72].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[72].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[72].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[72].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[72].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[72].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[72].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[72].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[72].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[72].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[72].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[72].freedoms_and_restrictions}</p> 
                </div>
        </div>   
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[73])}>
                <Link to = '/viewer'>
                    <img src={allZines[73].cover_page_url} alt = {allZines[73].title}  className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[73].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[73].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[73].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[73].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[73].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[73].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[73].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[73].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[73].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[73].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[73].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[73].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[73].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[73].freedoms_and_restrictions}</p> 
                </div>
        </div>
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[74])}>
                <Link to = '/viewer'>
                    <img src={allZines[74].cover_page_url} alt = {allZines[74].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[74].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[74].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[74].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[74].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[74].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[74].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[74].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[74].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[74].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[74].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[74].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[74].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[74].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[74].freedoms_and_restrictions}</p> 
                </div>
        </div> 
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[75])}>
                <Link to = '/viewer'>
                    <img src={allZines[75].cover_page_url} alt = {allZines[75].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[75].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[75].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[75].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[75].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[75].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[75].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[75].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[75].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[75].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[75].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[75].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[75].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[75].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[75].freedoms_and_restrictions}</p> 
                </div>
        </div> 
        <div className="each-fade" onMouseOver = {() => this.props.getCurrentZine(allZines[76])}>
                <Link to = '/viewer'>
                    <img src={allZines[76].cover_page_url} alt = {allZines[76].title} className = "vh-image"/>
                </Link>  
                <div className="metadata">
                    <h2 className = "title">{allZines[76].title}</h2>
                    <p className = "small-data"><span>Creator:</span> {allZines[76].creator}</p>
                    <p className = "small-data"><span>Content:</span><br></br> {allZines[76].content_description}</p>
                    <p className = "small-data"><span>Identifier:</span> {allZines[76].identifier}</p>
                    <p className = "small-data"><span>Year:</span> {allZines[76].date}</p>
                    <p className = "small-data"><span>Subject:</span> {allZines[76].subject}</p>
                    <p className = "small-data"><span>Language:</span> {allZines[76].language}</p> 
                    <p className = "small-data"><span>Union ID:</span> {allZines[76].union_id}</p>
                    <p className = "small-data"><span>Coverage:</span> {allZines[76].coverage}</p> 
                    <p className = "small-data"><span>Contributor:</span> {allZines[76].contributor}</p>
                    <p className = "small-data"><span>Publisher:</span> {allZines[76].publisher}</p>
                    <p className = "small-data"><span>Place of Publication:</span> {allZines[76].place_of_publication}</p>
                    <p className = "small-data"><span>Physical Description:</span> {allZines[76].physical_description}</p>
                    <p className = "small-data"><span>Freedoms and Restrictions:</span><br></br> {allZines[76].freedoms_and_restrictions}</p> 
                </div>
        </div> 
    </SlideShowFade> 
    <p className = 'view-pdf'>Click image to view PDF file.</p> 
   </div>
    )   
   }
 }

export default ZineSlidesShow