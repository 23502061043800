import React, { useEffect, useState } from 'react';
import allZines from './PDF';
import './pdf.css'



export default function PDFViewer(props) {


useEffect(() => {
    window.scrollTo(0, 0)
}, []) 


let zineCurrent = allZines.find(zine => zine.codeID === props.currentZine.codeID)
console.log(zineCurrent)
  return (
    <div>
      <iframe src={props.currentZine.pdf} title = "Zine PDF Viewer" allow = "autoplay"></iframe>
    </div>
  );
} 