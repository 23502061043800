import BeatTheBox from './BeatTheBox.pdf'
import FD from './FD.pdf'
import SurvivalRent from './SurvivalRent.pdf'
import WarNeighborhood from './WarNeighborhood.pdf'
import LivingSpace from './LivingSpace.pdf'
import NoCars from './NoCars.pdf'
import Biodiesel from './Biodiesel.pdf'
import Squatting from './Squatting.pdf'
import ShorterShowers from './ShorterShowers.pdf'
import Anarchist from './Anarchist.pdf'
import ListeningLand from './ListeningLand.pdf'
import HowNotArrested from './HowNotArrested.pdf'
import AttackingPrisons from './AttackingPrisons.pdf'
import CamerasEverywhere from './CamerasEverywhere.pdf'
import GreaterBK from './GreaterBK.pdf'
import Skin from './Skin.pdf'
import Abolition from './Abolition.pdf'
import Feminism from './Feminism.pdf'
import Prova from './Prova.pdf'
import RawSubstitute from './RawSubstitute.pdf'
import OutsideAgitators from './OutsideAgitators.pdf'
import BicycleMaintenance from './BicycleMaintenance.pdf'
import Composting from './Composting.pdf'
import Edible from './Edible.pdf'
import Mycology from './Mycology.pdf'
import Homeo1 from './Homeo1.pdf'
import Homeo2 from './Homeo2.pdf'
import Homeo3 from './Homeo3.pdf'
import Snot from './Snot.pdf'
import KnowYourRights from './KnowYourRights.pdf'
import Antiglobe from './Antiglobe.pdf'
import TimesUp from './TimesUp.pdf'
import Anarkata from './Anarkata.pdf'
import ABCSquatting from './ABCSquatting.pdf'
import DiagnosticFuture from './DiagnosticFuture.pdf'
import AnarchyPamphlet from './AnarchyPamphlet.pdf'
import DissidentsSurvival from './DissidentsSurvival.pdf'
import WhiteAlly from './WhiteAlly.pdf'
import StandingRock from './StandingRock.pdf'
import WarHood1 from './WarHood1.pdf'
import Militarism from './Militarism.pdf'
import Reflections from './Reflections.pdf'
import QuitSmoking from './QuitSmoking.pdf'
import Sandyaid from './Sandyaid.pdf'
import GuideWater from './GuideWater.pdf'
import GreaseCar from './GreaseCar.pdf'
import ElizabethCity from './ElizabethCity.pdf'
import AnarchistGlobalWarming from './AnarchistGlobalWarming.pdf'
import PlayNYC from './PlayNYC.pdf'
import SourceIssue4 from './SourceIssue4.pdf'
import Spooky from './Spooky.pdf'
import FeminishConnection from './FeministConnection.pdf'
import AnimalLiberation from './AnimalLiberation.pdf'
import ArtRevolution from './ArtRevolution.pdf'
import AnarchistSolution from './AnarchistSolution.pdf'
import TakeRisk from './TakeRisk.pdf'
import Catechisms from './Catechisms.pdf'
import ChangeEverything from './ChangeEverything.pdf'
import Anarquismo from './Anarquismo.pdf'
import WithoutTrace from './WithoutTrace.pdf'
import ArtZines from './ArtZines.pdf'
import BrokenTeapot from './BrokenTeapot.pdf'
import Bagels from './Bagels.pdf'
import Positions3 from './Positions3.pdf'
import DefundPolice from './DefundPolice.pdf'
import BillNotBored from './BillNotBored.pdf'
import GovernmentFurture from './GovernmentFuture.pdf'
import StrongHeart from './StrongHeart.pdf'
import Cointelpro from './Cointelpro.pdf' 
import MaroonPeople from './MaroonPeople.pdf'
import HipsterRacism from './HipsterRacism.pdf'
import RevoltGuards from './RevoltGuards.pdf'
import GWCrop from './GWCrop.pdf'
import RadicalSpaces from './RadicalSpaces.pdf'
import RadicalSpaces2021 from './RadicalSpaces2021.pdf'
import OccupyMachine from './OccupyMachine.pdf'
import OccupationMovement from './OccupationMovement.pdf'


const allZines = [ 
    {
        title: "Fighting Displacement in the Neighborhood: Anti-Eviction Organizing", 
        creator: "Frank Morales", 
        identifier: "MoRUS.Zine.2021.Hi.009",
        union_id: "TBD",
        content_description: "An explanation and discussion of how displacement occurs, is upheld and reinforced.",
        subject: "History | Housing",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2013,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/Sn2Atp2.png",
        pdf: FD, 
        codeID: 2
    }, 
    {
        title: "How Not to Get Arrested at a Demonstration", 
        creator: "Museum of Reclaimed Urban Space | Fly", 
        identifier: "MoRUS.Zine.2021.HT.012",
        union_id: "TBD",
        content_description: "Tips and pointers explaining how to decrease the odds of getting arrested at a demonstration or protest.",
        subject: "How To and DIY | Activism",
        place_of_publication: "New York, NY",
        publisher: "Museum of Reclaimed Urban Space",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/1M4X6P5.png",
        pdf: HowNotArrested,
        codeID: 12 
    },
    {
        title: "Tips 4 Squatting in NYC", 
        creator: "Unknown", 
        identifier: "MoRUS.Zine.2021.HT.006",
        union_id: "TBD",
        content_description: "A detailed guide and advice on how to squat successfully in New York City.",
        subject: "How To and DIY | Squatting",
        place_of_publication: "New York, NY",
        publisher: "n/a",
        contributor: "n/a",
        date: 2016,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/zTsuKj7.png",
        pdf: Squatting,
        codeID: 4
    },
    {
        title: "The War for Living Space: Spatial Deconcentration History in the Making", 
        creator: "Frank Morales", 
        identifier: "MoRUS.Zine.2021.Hi.005",
        union_id: "TBD",
        content_description: "An analysis of the Kerner Commission and the role of spatial deconcentration in the cycle of gentrification.",
        subject: "History | Housing",
        place_of_publication: "New York, NY",
        publisher: "Unkown",
        contributor: "n/a",
        date: 1997,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/amAVSsu.png",
        pdf: LivingSpace,
        codeID: 5   
    }, 
    {
        title: "War in the Neighborhood", 
        creator: "Seth Tobocman", 
        identifier: "MoRUS.Zine.2021.HI.017",
        union_id: "TBD",
        content_description: "A depiction of the struggles for housing, gentrification, squatting, police brutality and human rights that were fought in the surrounding areas of Tompkins Square Park in the 1980s and 90s.",
        subject: "History",
        place_of_publication: "New York, NY",
        publisher: "n/a",
        contributor: "n/a",
        date: 2016,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/6cmwsXA.png",
        pdf: WarNeighborhood,
        codeID: 6   
    },
    {
        title: "Banning Cars from Manhattan", 
        creator: "Paul Goodman", 
        identifier: "MoRUS.Zine.2021.EN.001",
        union_id: "TBD",
        content_description: "A proposal to ban private cars from Manhattan to improve New York City’s problems of traffic, air pollution, and housing. ",
        subject: "Environmentalism and Nature | Cars",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "Percival Goodman",
        date: 1961,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/T4JGAi2.png",
        pdf: NoCars,
        codeID: 7   
    },
    {
        title: "Biodiesel to the People!", 
        creator: "Brent Arrow Baker", 
        identifier: "MoRUS.Zine.2021.EN.004",
        union_id: "TBD",
        content_description: "Baker's reflection on how he became involved with biodiesel, and a description of its benefits.",
        subject: "Environmentalism and Nature | Cars",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "Fly",
        date: 2006,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Oakland",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/60Xul5j.png",
        pdf: Biodiesel,
        codeID: 8  
    },
    {
        title: "Skin in the Game", 
        creator: "Erik K. Ward", 
        identifier: "MoRUS.Zine.2021.Hi.021",
        union_id: "TBD",
        content_description: "How antisemitism intersects with and works to function in tandem with white nationalism and racism.",
        subject: "History | Race",
        place_of_publication: "Seattle, WA",
        publisher: "1312 Press",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/8FIT9UD.png",
        pdf: Skin,
        codeID: 16 
    }, 
    {
        title: "Beat the Box: The Insider's Guide to Outwitting the Lie Detector", 
        creator: "Dr. Vlad Kalashnikov", 
        identifier: "MoRUS.Zine.2021.HT.001",
        union_id: "TBD",
        content_description: "Informs the reader what to expect on a lie detector test. Conveys in concise and non technical language, how you can devise your own simple counter-measures to help tilt the odds in your favor.",
        subject: "How To and DIY | Police",
        place_of_publication: "El Dorado, AZ",
        publisher: "Desert Publications",
        contributor: "n/a",
        date: 1983,
        language: "English",
        physical_description: "physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/a34dJyk.png",
        pdf: BeatTheBox, 
        codeID: 1
    },
    {
        title: "Anarchist Basics", 
        creator: "Unknown", 
        identifier: "MoRuS.Zine.2021.ANA.010",
        union_id: "TBD",
        content_description: "A basic run-down of anarchist terms and concepts such as affinity groups, direct action, mutual aid, a collective, a consensus etc.",
        subject: "Anarchism | Politics",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/4XA5wki.png",
        pdf: Anarchist,
        codeID: 10 
    }, 
    {
        title: "Survival Without Rent", 
        creator: "Unknown", 
        identifier: "MoRUS.Zine.2021.HT.002",
        union_id: "TBD",
        content_description: "A step-by-step guide on how to combat NYC's housing insecurities and inequities. Tips on how to find housing. ",
        subject: "How To and DIY | Housing",
        place_of_publication: "New York, NY",
        publisher: "n/a",
        contributor: "n/a",
        date: 2013,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated.  Assume copyright.",
        cover_page_url: "https://i.imgur.com/Mb3bB5L.png",
        pdf: SurvivalRent,
        codeID: 3
    }, 
    {
        title: "Forget Shorter Showers: Why Personal Change Does Not Equal Political Change", 
        creator: " Derrick Jensen", 
        identifier: "MoRUS.Zine.2021.EN.005",
        union_id: "TBD",
        content_description: "A discussion of the harsh reality activists face in questioning the impact of personal change versus large scale and societal change.",
        subject: "Environmentalism and Nature | Accountability",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2009,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright",
        cover_page_url: "https://i.imgur.com/ntsHETj.png",
        pdf: ShorterShowers,
        codeID: 9 
    }, 
    {
        title: "Attacking Prisons at the Point of Production: A Brief Look at Militant Actions Against the Prison-Industrial Complex", 
        creator: "Green Anarchy", 
        identifier: "MoRUS.Zine.2021.PP.004",
        union_id: "TBD",
        content_description: "An anarchist approach to prison abolition.",
        subject: "Political - Police | Prisons",
        place_of_publication: "Eugene, OR",
        publisher: "Green Anarchy",
        contributor: "Romona Sharples",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/efwF7f3.png",
        pdf: AttackingPrisons,
        codeID: 13 
    }, 
    {
        title: "Listening to the Land", 
        creator: "Derrick Jensen", 
        identifier: "MoRUS.Zine.2021.Hi.003",
        union_id: "TBD",
        content_description: "An analysis of genocide and its effects on indigenous people and the land.",
        subject: "History | Race",
        place_of_publication: "Eugene, OR",
        publisher: "Green Anarchy Distribution",
        contributor: "Ward Churchill",
        date: 1995,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/umfXg0q.png",
        pdf: ListeningLand,
        codeID: 11 
    },
    {
        title: "Cameras Everywhere Safety Nowhere", 
        creator: "Unknown", 
        identifier: "MoRUS.Zine.2021.PP.002",
        union_id: "TBD",
        content_description: "Information about police's use of cameras as surveilence and as body cameras, and the false sense of safety that comes along with that.",
        subject: "Political - Police | Surveillance",
        place_of_publication: "Unknown",
        publisher: "CrimethInc",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/G2hFViD.png",
        pdf: CamerasEverywhere,
        codeID: 14 
    },
    {
        title: "Greater Brooklyn", 
        creator: "The Greater Brooklyn Zine Collective", 
        identifier: "MoRUS.Zine.2021.Hi.011",
        union_id: "TBD",
        content_description: "A handbook made with the purpose to connect the collective community/ies of Greater Brooklyn, promote its community-culture and spaces, and provide opportunity for involvement to all.",
        subject: "History | Community",
        place_of_publication: "New York, NY",
        publisher: "The Greater Brooklyn Zine Collective",
        contributor: "Sam Kelly",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Brooklyn",
        freedoms_and_restrictions: "Anti-Copyright",
        cover_page_url: "https://i.imgur.com/rBSowx1.png",
        pdf: GreaterBK,
        codeID: 15 
    }, 

    {
        title: "Abolition of Work", 
        creator: "Bob Black",     
        identifier: "MoRUS.Zine.2021.ANA.003",
        union_id: "TBD",
        content_description: "A take on why no one should work, bringing into question capitalism, gender norms, Marxism, etc.",
        subject: "Anarchism | Labor",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/2dwzdRx.png",
        pdf: Abolition,
        codeID: 17     
    }, 
    {
        title: "Profiles of a Provocateurs", 
        creator: "Kristian Williams",     
        identifier: "MoRUS.Zine.2021.ANA.007",
        union_id: "TBD",
        content_description: "A summary of three cases involving the use of provocateurs against the anarchist and radical environmentalist movement.",
        subject: "Anarchism | Environmentalism",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright",
        cover_page_url: "https://i.imgur.com/OEA5Clk.png",
        pdf: Prova,
        codeID: 18     
    },
    {
        title: "No Authority but Oneself", 
        creator: "Sharon Presley",     
        identifier: "MoRUS.Zine.2021.ANA.005",
        union_id: "TBD",
        content_description: "An analysis of Voltairine de Clayre's views on traditional gender roles, sex roles, marriage, beliefs, and political legacy.",
        subject: "Anarchism | Feminism",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright",
        cover_page_url: "https://i.imgur.com/P7YKvDC.png",
        pdf: Feminism,
        codeID: 19     
    }, 
    {
        title: "Raw Substitute", 
        creator: "Faith Day | Ellan Fishman | Pablo Garcia | Stephen Ielpi |  Bobby Kane | Ronaeld Smith | Steve Wishania",     
        identifier: "MoRUS.Zine.2021.ART.001",
        union_id: "TBD",
        content_description: "A poetry zine of LES writers.",
        subject: "Art | Poetry",
        place_of_publication: "New York, NY",
        publisher: "Aztek House #1",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/ZIBX5Mb.png",
        pdf: RawSubstitute,
        codeID: 20     
    },


    {
        title: "A rough guide to bicycle maintenance.", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HT.009",
        union_id: "TBD",
        content_description: "How to take care of your bike on your own (parts, tires, locking up, etc.).",
        subject: "How To and DIY | Bicycles",
        place_of_publication: "Portland, OR",
        publisher: "Time's Up",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/7lTwkx9.jpg",
        pdf: BicycleMaintenance,
        pdf_url: "https://drive.google.com/file/d/1Jdtvm2alprqHlPmTCLmqcQqJtOaIICaV/preview",
        codeID: 21
    },
     {
        title: "What They Mean When They Say Peace & The Making of ‘Outside Agitators’", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.2021.HI.015",
        union_id: "TBD",
        content_description: "Two essays discussing solidarity and justice in response to the rebellion that erupted in Ferguson, MO after the murder of Michael Brown.",
        subject: "History | Politics",
        place_of_publication: "Unknown",
        publisher: "Olopanax Publishing",
        contributor: "n/a",
        date: 2014,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Ferguson, MO",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/LKtUz2P.jpg",
        pdf: OutsideAgitators,
        pdf_url: "https://drive.google.com/file/d/10oRdwgOLzbg20sVlSkC-qOf9aUnDll15/preview",
        codeID: 22
    },
   {
        title: "Edible, Medicinal, & Utilitarian Plants", 
        creator: "Rowan WalkingWolf, PhD | Harun Highmountain, MD",     
        identifier: "MoRUS.Zine.2021.HT.003",
        union_id: "TBD",
        content_description: "Field guide to identifying and harvesting edible, medicinal, and utilitarian plants.",
        subject: "How To and DIY | Gardening",
        place_of_publication: "Unknown",
        publisher: "Yggdrasil Distro",
        contributor: "n/a",
        date: 2009,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Anti-copyright",
        cover_page_url: "https://i.imgur.com/QG4s80u.png",
        pdf: Edible,
        pdf_url: "https://drive.google.com/file/d/1Z51tN7Xldj76o-spgoCIIwC4Mlhl3zbG/preview",
        codeID: 24 
    },
   {
        title: "Radical Mycology", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.2021.HT.005",
        union_id: "TBD",
        content_description: "A field guide on the identification and use of fungi.",
        subject: "How To and DIY | Gardening",
        place_of_publication: "Unknown",
        publisher: "Spore Liberation Front (SLF)",
        contributor: "n/a",
        date: 2010,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Anticopyright",
        cover_page_url: "https://i.imgur.com/OkMvVcW.png",
        pdf: Mycology,
        pdf_url: "https://drive.google.com/file/d/1MPvUSBx75ah4BYni2aW8xpAXdYmGE_cF/preview",
        codeID: 25     
    }, 
    {
        title: "Home Composting Made Easy", 
        creator: "C. Forest McDowell, PhD  | Tricia Clark-McDowell",     
        identifier: "MoRUS.Zine.2021.HT.007",
        union_id: "TBD",
        content_description: "Step-by-step guide of different ways you can compost at home easily.",
        subject: "How To and DIY | Gardening",
        place_of_publication: "Eugene, OR",
        publisher: "Cortesia Press",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Copyright",
        cover_page_url: "https://i.imgur.com/uNqyCDP.jpg",
        pdf: Composting,
        pdf_url: "https://drive.google.com/file/d/1k6u2AX9hJPCWj6earEV5xV1gQuXbmH3M/preview",
        codeID: 23     
    },
    {
        title: "Homeo-Empathy 9th and C Part One: From the Beginning of the New World", 
        creator: "W.D. Bickerknocker",     
        identifier: "MoRUS.Zine.2021.HI.012",
        union_id: "TBD",
        content_description: "A history of the property at 155 Avenue C from 1825 through the 1980s. Part one of a three-part series.",
        subject: "History | Housing",
        place_of_publication: "Unknown",
        publisher: "Unkown",
        contributor: "Fly",
        date: 2014,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/mQI4BmG.jpg",
        pdf: Homeo1,
        pdf_url: "https://drive.google.com/file/d/1lVIg0YOlTOsjToKF6x6ZcYcT_z-P65zP/preview",
        codeID: 26     
    },
   {
        title: "Homeo-Empathy 9th and C Part Two: To the _ _ _  _ _  _ _ _ Derelict Dynasty", 
   creator: "W.D. Bickerknocker",     
        identifier: "MoRUS.Zine.2021.HI.013",
        union_id: "TBD",
        content_description: "A history of the property at 155 Avenue C from 1825 through the 1980s. Part two of a three-part series.",
        subject: "History | Housing",
        place_of_publication: "Unknown",
        publisher: "Unkown",
        contributor: "Fly",
        date: 2014,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/HUPSC0t.jpg",
        pdf: Homeo2,
        pdf_url: "https://drive.google.com/file/d/1LADqpYMJmMXWNmPxVMnTot14HAaT4w-_/preview",
        codeID: 27    
    },
   {
        title: "Homeo-Empathy 9th and C Part Three: … To the End of the Derelict Dynasty", 
        creator: "W.D. Bickerknocker",     
        identifier: "MoRUS.Zine.2021.HI.014",
        union_id: "TBD",
        content_description: "A history of the property at 155 Avenue C from 1825 through the 1980s. Part three of a three-part series.",
        subject: "History | Housing",
        place_of_publication: "Unknown",
        publisher: "Unkown",
        contributor: "Fly",
        date: 2014,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/cGu37sn.jpg",
        pdf: Homeo3,
        pdf_url: "https://drive.google.com/file/d/1KyiT1Fz-NTfeo_gNZ119KzJbtQXW3TQq/preview",
        codeID: 28 
    },
    {
        title: "The Chutzpah of the Snot Rocketeers", 
        creator: "Twine | R.",     
        identifier: "MoRUS.Zine.2021.HT.004",
        union_id: "TBD",
        content_description: "How and why to ride your bike year-long, with specific tips on how to ride your bike in the winter successfully/pleasantly.",
        subject: "How To and DIY | Bicycles",
        place_of_publication: "New York, NY",
        publisher: "estuaries comics",
        contributor: "n/a",
        date: 2010,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/4UJTEWw.png",
        pdf: Snot,
        pdf_url: "https://drive.google.com/file/d/1MggY1pq_1zZSqsRgn7WHpx6weUQnj94H/preview",
        codeID: 29    
    },
   {
        title: "Know Your Rights Manual for NYC Messengers", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.2021.HT.013",
        union_id: "TBD",
        content_description: "A detailed guide of rights of a bike messenger.",
        subject: "How To and DIY | Bicycles",
        place_of_publication: "New York, NY",
        publisher: "The New York Bike Messenger Association",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/9xSHqdH.png",
        pdf: KnowYourRights,
        pdf_url: "https://drive.google.com/file/d/13w5AZSiPeYsrO2mFi_rjZcoZYleD-is4/preview",
        codeID: 30     
    },
   {
        title: "From Antiglobe to Antifa: A Recent History of Anarchist Struggle in NYC", 
        creator: "some NYC Anarchists",     
        identifier: "MoRUS.Zine.2021.ANA.008",
        union_id: "TBD",
        content_description: "A summary and examination of twenty years of anarchist activity in New York City from 1999 to 2019.",
        subject: "Anarchism | Activism",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2019,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/B4TyVjy.jpg",
        pdf: Antiglobe,
        pdf_url: "https://drive.google.com/file/d/1mfpsy23tBXisn1iZWOi_qHc0zBdwpAot/preview",
        codeID: 31    
    },
   {
        title: "Time's Up! History", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.2021.EN.010",
        union_id: "TBD",
        content_description: "A history of Time's Up and how it came to be.",
        subject: "Environmentalism and Nature | Bicycles",
        place_of_publication: "New York, NY",
        publisher: "Time’s Up!",
        contributor: "Fly",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "All Images are subject to copyright.",
        cover_page_url: "https://i.imgur.com/pK0ZDuB.jpg",
        pdf: TimesUp,
        pdf_url: "https://drive.google.com/file/d/1khu87mvdC4JtUOQouZX1wsewoaFEiK-h/preview",
        codeID: 32    
    },
    {
        title: "Anarkata: A Statement", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.20201.ANA.012",
        union_id: "TBD",
        content_description: "A explanation of the definition, tradition, politics, and praxis of Anarkata; a movement of Black anarchism and Black nationalism.",
        subject: "Anarchism | Race",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/vUos8gZ.jpg",
        pdf: Anarkata,
        pdf_url: "https://drive.google.com/file/d/18yuV8qB5VjJ0_pybot-vjLrgfpm3eYLm/preview",
        codeID: 33   
    },
   {
        title: "The ABC's of Squatting", 
        creator: "F. Morales",     
        identifier: "MoRUS.Zine.2021.HT.008",
        union_id: "TBD",
        content_description: "Advice and how-tos of squatting. Topics range from the search for and securing of a building. Morales conveys the importance of collective decision making and provides a list of important items.",
        subject: "How To and DIY | Squatting",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/eaUuTbp.jpg",
        pdf: ABCSquatting,
        pdf_url: "https://drive.google.com/file/d/1ePVlGDWU7akg4-xCJQgLMlIOmREYE5gW/preview",
        codeID: 34   
    },
   {
        title: "Diagnostic of the Future A Forecast: Between the Crisis of Democracy and the Crisis of Capitalism", 
        creator: "Peter Gelderloos",     
        identifier: "MoRUS.Zine.2021.ANA.015",
        union_id: "TBD",
        content_description: "A discussion between the crisis of democracy and the crisis of capitalism.",
        subject: "Anarchism | Politics",
        place_of_publication: "Unknown",
        publisher: "CrimethInc",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/io6zwiZ.jpg",
        pdf: DiagnosticFuture,
        pdf_url: "https://drive.google.com/file/d/12Q-I_cOauVXYqnxfqHj99QSYhSUdCkw1/preview",
        codeID: 35     
    },
   {
        title: "Anarchy: a pamphlet", 
        creator: "Erico Malatesta",     
        identifier: "MoRUS.Zine.2021.ANA.001",
        union_id: "TBD",
        content_description: "Originally published in the English translation of the monthly journal, Freedom and reprinted in zine format it provides a definition of anarchy, a description of its terms and language, and the circumstances that demand its implementation. Accompanied with a brief biography of the author.",
        subject: "Anarchism | Politics",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/cqDoFkt.jpg",
        pdf: AnarchyPamphlet,
        pdf_url: "https://drive.google.com/file/d/1Bs6oHw70o2cF8RnUBuEKaCLr8B1kRu7l/preview",
        codeID: 36     
    },
    {
        title: "Dissident’s Guide to Survival, The", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.2021.HT.011",
        union_id: "TBD",
        content_description: "A field manual to encounters with law enforcement, corporate security, and other hazards. Created by the Anti-Repression Committee of Occupy Wall Street Activist Legal Working Group.",
        subject: "How To and DIY | Activism",
        place_of_publication: "New York, NY",
        publisher: "The Anti-Repression Committee of Occupy Wall Street Activist Legal Working Group.",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/MsS7QDX.jpg",
        pdf: DissidentsSurvival,
        pdf_url: "https://drive.google.com/file/d/1m8o6DXl-S6lOL6W_p0D7EaHbso3Os0of/preview",
        codeID: 37     
    },
   {
        title: "Another Word for White Ally is Coward", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.ANA.018",
        union_id: "TBD",
        content_description: "A critique of the concept of a White Ally.",
        subject: "Anarchism | Race",
        place_of_publication: "St. Louis, MO",
        publisher: "Anti-State STL",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "St. Louis",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/g2z6TtV.jpg",
        pdf: WhiteAlly,
        pdf_url: "https://drive.google.com/file/d/1B2ZjKjLuQ_fFBUDZrc9YjMSqc-0A5gNk/preview",
        codeID: 38    
    },
   {
        title: "Dispatches from Standing Rock: Against the Dakota Access Pipeline & its World", 
         creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HI.016",
        union_id: "TBD",
        content_description: "Different stories and personal reflections from those who protested the Dakota Access Pipeline.",
        subject: "History | Race",
        place_of_publication: "Unknown",
        publisher: "Ill Will Editions",
        contributor: "n/a",
        date: 2016,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Standing Rock",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright",
        cover_page_url: "https://i.imgur.com/lMf4Ofv.jpg",
        pdf: StandingRock,
        pdf_url: "https://drive.google.com/file/d/1zfeYai-2cq2e5h3_hbP2I6K2rB00LfZr/preview",
        codeID: 39     
    },
    {
        title: "War in the Neighborhood: Chapter 1", 
        creator: "Seth Tobocman",     
        identifier: "MoRUS.Zine.2021.HI.018",
        union_id: "TBD",
        content_description: "The first chapter of Tobocman’s graphic novel War in the Neighborhood. A depiction of the struggles for human rights and housing in the Lower East Side in the face of police brutality and gentrification during the 1980s and 1990s.",
        subject: "History | Housing",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/NOp1NYT.jpg",
        pdf: WarHood1,
        pdf_url: "https://drive.google.com/file/d/11ijXL3R6FN-6KZgV3d6wJUt70qU_egKh/preview",
        codeID: 40     
    }, 
    {
        title: "Against Militarism: The State, Exploitation, and War", 
        creator: "Wolfi Landstreicher",     
        identifier: "MoRUS.Zine.2021.HI.004",
        union_id: "TBD",
        content_description: "A commentary on exploitation, war, and militarism in the recent decades.",
        subject: "History | Politics",
        place_of_publication: "Global",
        publisher: "Unkown",
        contributor: "n/a",
        date: 1996,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/UIG6t4j.jpg",
        pdf: Militarism,
        pdf_url: "https://drive.google.com/file/d/1lkPdzU38hQ5JPIrgeRqHMR4py6Sqbdgi/preview",
        codeID: 41   
    },
   {
        title: "Reflections on an Eviction - For the Joyful Militants", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HI.010",
        union_id: "TBD",
        content_description: "A reflection on the eviction of a Berlin house project in February 2011 however in spite of this a celebration of the joy of militants.",
        subject: "History | Housing",
        place_of_publication: "Berlin, Germany",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2013,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Berlin",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/RAXsfRr.jpg",
        pdf: Reflections,
        pdf_url: "https://drive.google.com/file/d/1ByUS5JkJ7wiIKYCv-TCJ4McDGdlP13eq/preview",
        codeID: 42
    },
    {
        title: "Quit Smoking: A DIY Guide by Ex-Smokers", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HT.014",
        union_id: "TBD",
        content_description: "Advice on how to quit smoking cigarettes, as well as a history on the ties of smoking to capitalism and anarchy.",
        subject: "How To and DIY | Smoking",
        place_of_publication: "unknown",
        publisher: "Institute for experimental freedom",
        contributor: "Deconstruction Workers Local 700",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "n/a",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/D3TewZ3.jpg",
        pdf: QuitSmoking,
        pdf_url: "https://drive.google.com/file/d/1wQ9NaeU6eE48t2YInUN93XpKNcOujTfV/preview",
        codeID: 43     
    },
    {
        title: "What is Mutual Aid? #SandyAid", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HT.015",
        union_id: "TBD",
        content_description: "An explanation of mutual aid through the example of Occupy Sandy.",
        subject: "Activism | Environmentalism and Nature",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/ZcK8xGM.jpg",
        pdf: Sandyaid,
        pdf_url: "https://drive.google.com/file/d/1k4luAcsfZdgGMnqUzEmWa0aLPONPefO5/preview",
        codeID: 44
    },
    {
        title: "Guide to Water", 
        creator: "Guerrilla Graywater Girls",     
        identifier: "MoRUS.Zine.2021.EN.002",
        union_id: "TBD",
        content_description: "A reflection on how the Guerrilla Graywater Girls dealt with their own personal plumbing issues, and how that inspired them to learn about rivers, dams, water, power, and more.",
        subject: "Environmentalism and Nature | Water",
        place_of_publication: "California",
        publisher: "Microcosm Publishing",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "California",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/NJPCddy.png",
        pdf: GuideWater,
        pdf_url: "https://drive.google.com/file/d/1N6qeA5iQ1RL2ARFQ0EX7zRRLUSnalhBI/preview",
        codeID: 45
    },
    {
        title: "Adventures in the Land of Greasecars and Fireflies", 
        creator: "S. Scatter",     
        identifier: "MoRUS.Zine.2021.EN.003",
        union_id: "TBD",
        content_description: "An exploration of alternative fuels and their various uses.",
        subject: "Environmentalism and Nature | Cars",
        place_of_publication: "Unknown",
        publisher: "Uknown",
        contributor: "n/a",
        date: 2004,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/ZjNXypw.jpg",
        pdf: GreaseCar,
        pdf_url: "https://drive.google.com/file/d/1FLs_gHp95NJnZQK_Ok6K4DjMAT3pIY_o/preview",
        codeID: 46    
    },
    {
        title: "Elizabeth Street Garden Educational Coloring Book", 
        creator: "Liz Shavrick | Kamal Adisa",     
        identifier: "MoRUS.Zine.2021.EN.006",
        union_id: "TBD",
        content_description: "A coloring book educating the public on the history and impact of the Elizabeth Street Garden with the hopes to support the space.",
        subject: "Environmentalism and Nature | Gardening",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2019,
        language: "English",
        physical_description: "Digital",
        coverage: "Lower East Side",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/soDdK5u.jpg",
        pdf: ElizabethCity,
        pdf_url: "https://drive.google.com/file/d/1vSg2Kk1b8E0yBJbczZFaE_0svXogB9Yk/preview",
        codeID: 47
    },
    {
        title: "An Anarchist Solution to Global Warming", 
        creator: "Peter Gelderloos",     
        identifier: "MoRUS.Zine.2021.EN.007",
        union_id: "TBD",
        content_description: "A guide to an anarchist solution to global warming and its effects on both the environment and the social world. ",
        subject: "Environmentalism and Nature | Climate Change",
        place_of_publication: "Louisville, KY",
        publisher: "No Boarders: Louisville's Radical Lending Library ",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/L1wYXDH.jpg",
        pdf: AnarchistGlobalWarming,
        pdf_url: "https://drive.google.com/file/d/1XeVnZdJ8Qr0rOWy8Ywz4XhKzqHIfqxcs/preview",
        codeID: 48
    },
    {
        title: "plaNYC 2030 Now", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.EN.008",
        union_id: "TBD",
        content_description: "In response to Mayor Bloomberg's vision from greening the city, Time's Up proposes: why wait till 2030? Time's Up highlights bicycles as an effective solution to green-up the city.",
        subject: "Environmentalism and Nature | Climate Change",
        place_of_publication: "New York, NY",
        publisher: "Time’s Up!",
        contributor: "Sabrina Jones",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/SvE9SkS.jpg",
        pdf: PlayNYC,
        pdf_url: "https://drive.google.com/file/d/1HiRkvz7rWtGT1-RkRXfMQ2Qwrd6MVLGV/preview",
        codeID: 49
    },
    {
        title: "Source: Issue #4 Water & Energy", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.EN.009",
        union_id: "TBD",
        content_description: "A guide to different tips and tricks on how to make your life more sustainable.",
        subject: "Environmentalism and Nature | Activism",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/xJbrh8s.png",
        pdf: SourceIssue4,
        pdf_url: "https://drive.google.com/file/d/137oL9UbECgz6LxxjyknLyB9hCLb-32bn/preview",
        codeID: 50
    },
    {
        title: "Enter the Spook-ooky Realm of: Critical Mass Cheers!", 
        creator: "Ms. Mary Christmas aka the DOCTOR OF DEATH",     
        identifier: "MoRUS.Zine.2021.EN.011",
        union_id: "TBD",
        content_description: "Chants and songs to sing at critical mass bike ride assemblies.",
        subject: "Environmentalism and Nature | Bicycles",
        place_of_publication: "New York, NY",
        publisher: "Time's Up!",
        contributor: "n/a",
        date: 2002,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/k6LgKga.jpg",
        pdf: Spooky,
        pdf_url: "https://drive.google.com/file/d/1ZcG3BJ1dQvdNiqh4rBOEJruR4kyYoX5l/preview",
        codeID: 51
    },
    {
        title: "Anarchism: The Feminist Connection", 
        creator: "Peggy Kornegger",     
        identifier: "MoRUS.Zine.2021.ANA.002",
        union_id: "TBD",
        content_description: "A reflection on the history of anarchism and how it ties to feminism, and how to apply them to each other.",
        subject: "Anarchism | Feminism",
        place_of_publication: "Unknown",
        publisher: "boa (bevy of anarchist feminists)",
        contributor: "n/a",
        date: 1985,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/T5YzVMc.jpg",
        pdf: FeminishConnection,
        pdf_url: "https://drive.google.com/file/d/1JtNOf2d5eEciQpKj5ndRH4UMe8E_Eo0M/preview",
        codeID: 52 
    },
    //start here
    {
        title: "Animal Liberation and Social Revolution: A Vegan Perspective on Anarchism or an Anarchist Perspective on Veganism", 
        creator: "Brian A. Dominick",     
        identifier: "MoRUS.Zine.2021.ANA.004",
        union_id: "TBD",
        content_description: "A commentary on veganism, radical veganism, social revolution, and animal liberation.",
        subject: "Anarchism | Animal Rights",
        place_of_publication: "Unknown",
        publisher: "Pet Roach Press",
        contributor: "Joseph M. Smith",
        date: 1997,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Anti-copyright",
        cover_page_url: "https://i.imgur.com/hwHDEvo.jpg",
        pdf: AnimalLiberation,
        pdf_url: "https://drive.google.com/file/d/1y5F0xwrUwD31hu5tYT_AnH0r-e_ZNnMP/preview",
        codeID: 53   
    },
   {
        title: "Art of Revolution, The", 
        creator: "Howard Zinn",     
        identifier: "MoRUS.Zine.2021.ANA.006",
        union_id: "TBD",
        content_description: "An analysis and commentary of how anarchy and revolution work in modern-day life.",
        subject: "Anarchism | Politics",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global ",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/OmQ8egK.jpg",
        pdf: ArtRevolution,
        pdf_url: "https://drive.google.com/file/d/167mjN4_aElx86sfIQh_Iy6-zRa-iDYBp/preview",
        codeID: 54
    },
   {
        title: "Take A Risk", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.ANA.009",
        union_id: "TBD",
        content_description: "A assessment of risk and why it is important. As well as how to plan for risk.",
        subject: "Anarchism | Police",
        place_of_publication: "New York, NY",
        publisher: "The Broken Window",
        contributor: "n/a",
        date: 2019,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Anti-copyright",
        cover_page_url: "https://i.imgur.com/Mnd87IM.jpg",
        pdf: AnarchistSolution,
        pdf_url: "https://drive.google.com/file/d/1HCkJpj13MNMz67SoGve2cTlCydPaDS0j/preview",
        codeID: 55 
    },
   {
        title: "Anarchist Solution to the Problem of Crime, The ", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.ANA.011",
        union_id: "TBD",
        content_description: "An anarchist approach to an alternative form of law enforcement, and overall societal changes in regards to criminal justice.",
        subject: "Anarchism | Police",
        place_of_publication: "Unknown",
        publisher: "SRAF New Directions",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/McleLNA.jpg",
        pdf: TakeRisk,
        pdf_url: "https://drive.google.com/file/d/1ejUz-qqvz4jwMFEgMILttpuBKmsaC7O6/preview",
        codeID: 56
    },  
    {
        title: "Catechisms, The", 
        creator: "Michael Bakunin",     
        identifier: "MoRUS.Zine.2021.ANA.013",
        union_id: "TBD",
        content_description: "Exploring different points on freedom and revolution.",
        subject: "Anarchism | Politics",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/Ur0wuHc.jpg",
        pdf: Catechisms,
        pdf_url: "https://drive.google.com/file/d/1B6nR7E2xDaEh3mbU3m8so1SPj0E5PDsi/preview",
        codeID: 57
    },
   {
        title: "To Change Everything", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.ANA.014",
        union_id: "TBD",
        content_description: "An exploration of the different ways anarchy can generate change.",
        subject: "Anarchism | Politics",
        place_of_publication: "Unknown",
        publisher: "CrimethInc",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/DiZdj9T.jpg",
        pdf: ChangeEverything,
        pdf_url: "https://drive.google.com/file/d/1jZy0aKDX1Pa9o-6R9dwawtJMdTiCcVt9/preview",
        codeID: 58 
    },
   {
        title: "4 Fundamentos del Anarquismo", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.ANA.016",
        union_id: "TBD",
        content_description: "Spanish language zine breaking down fundamentals of anarchy.",
        subject: "Anarchism | Politics",
        place_of_publication: "Jalisco, Mexico",
        publisher: "Movimiento libertario de Jalisco",
        contributor: "n/a",
        date: "Unknown",
        language: "Spanish",
        physical_description: "Physical, bound",
        coverage: "Jalisco, Mexico",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/2hqQAQ3.jpg",
        pdf: Anarquismo,
        pdf_url: "https://drive.google.com/file/d/1jtGefxDqiuoytPNSbFr58TFG3TE0EfRq/preview",
        codeID: 59
    },
   {
        title: "Without A Trace", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.ANA.017",
        union_id: "TBD",
        content_description: "A commonsense guide to looking after oneself regarding the police's use of forensic technology.",
        subject: "Anarchism | Police",
        place_of_publication: "United Kingdom",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "United Kingdom",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/RiLpD4x.jpg",
        pdf: WithoutTrace,
        pdf_url: "https://drive.google.com/file/d/1kh67f_DOfDV_0sM076fAQsjB40sUFR4u/preview",
        codeID: 60    
    },
   {
        title: "Art in Zines, The", 
        creator: "Suckzoo Han",     
        identifier: "MoRUS.Zine.2021.ART.002",
        union_id: "TBD",
        content_description: "A compilation of ABC No Rio Gallery/Performance Space's 'The Art in Zines' 2007 Exhibition.",
        subject: "Art | Design",
        place_of_publication: "New York, NY",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2007,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/C8FLKy3.jpg",
        pdf: ArtZines,
        pdf_url: "https://drive.google.com/file/d/1jP3kInCSskjKC-BBLR1ZK52zi-9lBV14/preview",
        codeID: 61     
    }, 
    {
        title: "Broken Teapot, The", 
        creator: "Unkown",     
        identifier: "MoRUS.Zine.2021.ART.003",
        union_id: "TBD",
        content_description: "Various reflections on dealing with pain and painful experiences.",
        subject: "Art | Trauma",
        place_of_publication: "Seattle, WA",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2014,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/ov2KK60.jpg",
        pdf: BrokenTeapot,
        pdf_url: "https://drive.google.com/file/d/1nbK32eYjY1jMT7OZHTcGfrM22imZt26q/preview",
        codeID: 62   
    },
   {
        title: "Dave Dumpsterland and the Great Wall of Bagels", 
        creator: "Quinn",     
        identifier: "MoRUS.Zine.2021.ART.004",
        union_id: "TBD",
        content_description: "A story of Dave's endless supply of bagels, and how he learns to appreciate other foods as well.",
        subject: "Art | Illustrations",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bold",
        coverage: "n/a",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/QkunIxQ.jpg",
        pdf: Bagels,
        pdf_url: "https://drive.google.com/file/d/10VysqG9i5-_lIDERq85NO0EypXKPb2tl/preview",
        codeID: 63    
    },
   {
        title: "3 Positions Against Prison", 
        creator: "August O'Claire",     
        identifier: "MoRUS.Zine.2021.PP.001",
        union_id: "TBD",
        content_description: "A statement offering a broad critique of many commonly-held assumptions and positions that could characterize leftist and anarchist political practice with regard to prisons and prisoners.",
        subject: "Political - Police | Prison",
        place_of_publication: "North Carolina",
        publisher: "NC Piece Corps",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/wgDh3RQ.jpg",
        pdf: Positions3,
        pdf_url: "https://drive.google.com/file/d/16Ie0JmD7W8WkAfIrnn7vMs4snFZ8wJDr/preview",
        codeID: 64    
    }, 
    {
        title: "Defund the Police: What it Means and Why the Time is Now", 
        creator: "Black and Pink",     
        identifier: "MoRUS.Zine.2021.PP.003",
        union_id: "TBD",
        content_description: "A discussion on why now is the time to defund and abolish police and prisons.",
        subject: "Political - Police | Prison",
        place_of_publication: "Unknown",
        publisher: "irrelevant press",
        contributor: "Romana Sharples",
        date: 2020,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/TvFnEbA.jpg",
        pdf: DefundPolice,
        pdf_url: "https://drive.google.com/file/d/18uXBgZOnUrnz2WoCGeiZM0iEb02bDXCi/preview",
        codeID: 65     
    }, 
    {
        title: "Bill Not Bored Map", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.PP.005",
        union_id: "TBD",
        content_description: "A guide to mapping surveillance cameras, as well as multiple maps of surveillance cameras across NYC.",
        subject: "Political – Police | Surveillance",
        place_of_publication: "New York, NY",
        publisher: "Editions AquaViva",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/5XYYRge.png",
        pdf: BillNotBored,
        pdf_url: "https://drive.google.com/file/d/1YAFUmBM7JiATsO7EkObNg564Uo7UnfSm/preview",
        codeID: 66     
    },
   {
        title: "Government in the Future", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.Hi.001",
        union_id: "TBD",
        content_description: "A replicated 1970 speech in which Chomsky relays a defense of the libertarian socialist or anarchist vision.",
        subject: "History | Politics",
        place_of_publication: "Oakland, CA",
        publisher: "AK Press",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/C2Kv3bp.jpg",
        pdf: GovernmentFurture,
        pdf_url: "https://drive.google.com/file/d/1mNpc_r1KNg54o0Qga-5C2WWLigxDAaz0/preview",
        codeID: 67    
    }, 
   {
        title: "Strong Hearts #1", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HI.002",
        union_id: "TBD",
        content_description: "A recollection of stories, memories, and reflections from an ex-ALF prisoner.",
        subject: "History | Animal Rights",
        place_of_publication: "Arizona",
        publisher: "Unknown",
        contributor: "n/a",
        date: 1994,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/c1G9cbq.jpg",
        pdf: StrongHeart,
        pdf_url: "https://drive.google.com/file/d/1_PV-XWPyAwgyT6qbPTTfH5hioUY2EtBY/preview",
        codeID: 68    
    },
    {
        title: "Cointelpro: The Danger We Face", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HI.006",
        union_id: "TBD",
        content_description: "An explanation of Cointelpro, what they did and why it was harmful, and an organization of public opposition to covert intervention.",
        subject: "History | Politics",
        place_of_publication: "Unknown",
        publisher: "Loincloth Press",
        contributor: "n/a",
        date: 1998,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/SJcrjjN.jpg",
        pdf: Cointelpro,
        pdf_url: "https://drive.google.com/file/d/10O6HpXDqa2MtYgf1uHlU_IA3Iax0iJ3b/preview",
        codeID: 69    
    },
   {
        title: "Maroon People: Cultures of New World Resistance", 
        creator: "Napaquetzalli",     
        identifier: "MoRUS.Zine.2021.HI.007",
        union_id: "TBD",
        content_description: "An informative retelling of the Maroon People and their culture, customs, and beliefs.",
        subject: "History | Race",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2011,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Caribbean",
        freedoms_and_restrictions: "Anti-copyright",
        cover_page_url: "https://i.imgur.com/OtixwIk.jpg",
        pdf: MaroonPeople,
        pdf_url: "https://drive.google.com/file/d/1peZGt2Tigw5XmMuqUgwZWPCQsiC46dpP/preview",
        codeID: 70     
    },
   {
        title: "A Complete Guide to 'Hipster Racism'", 
        creator: "Lindy West",     
        identifier: "MoRUS.Zine.2021.HI.008",
        union_id: "TBD",
        content_description: "A call-out of the realities of those who claim to be anti-racist, however in actuality support and reinforce racism in their everyday lives... proving for them to be in fact, racist. ",
        subject: "History | Race",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: 2012,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/kGJQkvQ.jpg",
        pdf: HipsterRacism,
        pdf_url: "https://drive.google.com/file/d/1nUOIlKlopaPaMXEpSR9B6oC3Wr0tVjuU/preview",
        codeID: 71    
    },
   {
        title: "Coming Revolt of the Guards, The", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HI.019",
        union_id: "TBD",
        content_description: "A commentary on the reliance of systems and institutions of or in control. Author unknown, excerpted from A People's History of the United States by Howard Zinn.",
        subject: "History | Politics",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "Howard Zinn",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/5nZ8y1Z.jpg",
        pdf: RevoltGuards,
        pdf_url: "https://drive.google.com/file/d/1u8CXCL8Co2IebyEmgwtdKwpGHte4fp_J/preview",
        codeID: 72   
    },
    {
        title: "My First Little Book of GM Crop Decontamination", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.HI.020",
        union_id: "TBD",
        content_description: "Designed informational and entertainment purposes, an informational booklet about decontaminating GMO trial sites.",
        subject: "History | Environmentalism and Nature",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "USA",
        freedoms_and_restrictions: "Anti-copyright",
        cover_page_url: "https://i.imgur.com/LlOuiOy.jpg",
        pdf: GWCrop,
        pdf_url: "https://drive.google.com/file/d/1rIrwUKYKPwSvVYxr-5hlfxNmdAQP8R4B/preview",
        codeID: 73     
    },
   {
        title: "Radical Spaces Map NYC 2017", 
        creator: "Museum of Reclaimed Urban Space",     
        identifier: "MoRUS.Zine.2021.HI.022",
        union_id: "TBD",
        content_description: "A list of radical spaces, community living spaces, community gardens, and radical websites across NYC.",
        subject: "History | Activism",
        place_of_publication: "New York, NY",
        publisher: "Museum of Reclaimed Urban Space",
        contributor: "",
        date: 2017,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City ",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright",
        cover_page_url: "https://i.imgur.com/gaBXMvp.jpg",
        pdf: RadicalSpaces,
        pdf_url: "https://drive.google.com/file/d/1Kp9kGBXmPgX1IchWsoSOJLHtgyDwyQid/preview",
        codeID: 74    
    },
   {
        title: "Radical Spaces New York City 2021", 
        creator: "Museum of Reclaimed Urban Space",     
        identifier: "MoRUS.Zine.2021.HI.023",
        union_id: "TBD",
        content_description: "A list of radical spaces, community living spaces, community gardens, and radical websites across NYC",
        subject: "History | Activism",
        place_of_publication: "New York, NY",
        publisher: "Museum of Reclaimed Urban Space",
        contributor: "n/a",
        date: 2021,
        language: "English",
        physical_description: "Physical, bound",
        coverage: "New York City",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/fED7FYr.jpg",
        pdf: RadicalSpaces2021,
        pdf_url: "https://drive.google.com/file/d/1FPClDqtWh04D2gW4O3KQoYn8DXfB0lsl/preview",
        codeID: 75     
    },
   {
        title: "Occupy the Machine: Our Bodies Will be our Demand: Open Letter to the Occupy Movement", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.Occ.001",
        union_id: "TBD",
        content_description: "A description of how occupy movements function and thrive successfully.",
        subject: "Occupy | Politics",
        place_of_publication: "Unknown",
        publisher: "Deep Green Resistance ",
        contributor: "Nicolas Lampert",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/nzmLbjH.jpg",
        pdf: OccupyMachine,
        pdf_url: "https://drive.google.com/file/d/1HutbcD3reeXuQC-w4PW3BvFxOJzvwcO7/preview",
        codeID: 76     
    },
    {
        title: "Occupy: Your Guide to the International Occupation Movement of 2011", 
        creator: "Unknown",     
        identifier: "MoRUS.Zine.2021.Occ.002",
        union_id: "TBD",
        content_description: "A explanation of models and theories critical to the international occupy movement of 2011.",
        subject: "Occupy | Politics",
        place_of_publication: "Unknown",
        publisher: "Unknown",
        contributor: "n/a",
        date: "Unknown",
        language: "English",
        physical_description: "Physical, bound",
        coverage: "Global",
        freedoms_and_restrictions: "Freedoms and restrictions are not stated. Assume copyright.",
        cover_page_url: "https://i.imgur.com/uQxBPtd.jpg",
        pdf: OccupationMovement,
        pdf_url: "https://drive.google.com/file/d/1ZYvSBnznmx0wVmoK9Ov_g7KzTqMqgU5b/preview",
        codeID: 77   
}



]

export default allZines; 