import React from 'react'
import './grid.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'

export const GridViewCard = (props) => {

const [metaDataView, getMetaData] = useState(false)

  return(
    <div>
      { metaDataView === false ? 
      <figure>
          <div className = "img-div">
            <Link to = '/viewer'>  
              <img onMouseOver = {() => props.getCurrentZine(props.zine)} alt = {props.zine.title} className = "grid-img" src = {props.zine.cover_page_url}></img>
            </Link>
          </div>
          <Fade bottom> 
            <button className = "grid-btn" onClick ={() => getMetaData(!metaDataView)}>See Metadata </button> 
          </Fade>
      </figure> : 
      <figure className = "metadata-div">
        <Link to = '/viewer'>
          <img onMouseOver = {() => props.getCurrentZine(props.zine)} className = "grid-img" src = {props.zine.cover_page_url} alt = {props.zine.title}></img>
        </Link>
        <div className = "border-grid"></div> 
        <Zoom>  
        <p className = 'grid-data'><span className = "grid-span">Title:</span> {props.zine.title}</p>
          <p className = 'grid-data'><span className = "grid-span">Creator:</span> {props.zine.creator}</p>
          <p className = "grid-data"><span className = "grid-span">Content:</span><br></br> {props.zine.content_description}</p>
          <p className = "grid-data"><span className = "grid-span">Identifier:</span> {props.zine.identifier}</p>
          <p className = "grid-data"><span className = "grid-span">Year:</span > {props.zine.date}</p>
          <p className = "grid-data"><span className = "grid-span">Subject:</span> {props.zine.subject}</p>
          <p className = "grid-data"><span className = "grid-span">Language:</span> {props.zine.language}</p> 
          <p className = "grid-data"><span className = "grid-span">Union ID:</span> {props.zine.union_id}</p>
          <p className = "grid-data"><span className = "grid-span">Coverage:</span> {props.zine.coverage}</p> 
          <p className = "grid-data"><span className = "grid-span">Contributor:</span> {props.zine.contributor}</p>
          <p className = "grid-data"><span className = "grid-span">Publisher:</span> {props.zine.publisher}</p>
          <p className = "grid-data"><span className = "grid-span">Place of Publication:</span> {props.zine.place_of_publication}</p>
          <p className = "grid-data"><span className = "grid-span">Physical Description:</span> {props.zine.physical_description}</p>
          <p className = "grid-data"><span className = "grid-span">Freedoms and Restrictions:</span> {props.zine.freedoms_and_restrictions}</p>
          <button className = "hide-btn" onClick ={() => getMetaData(!metaDataView)}>X</button>
          <div className = "border-grid"></div> 
        </Zoom>
      </figure>  }
    </div>
   )

 }